<template>
    <v-row>
        <v-dialog transition="scroll-y-transition" fullscreen v-model="dialogApp">
            <v-btn fixed top class="mt-10" right @click="dialogApp = false" fab color="primary"><v-icon dark>mdi-close</v-icon></v-btn>
            <iframe v-if="lStorage" style="border:0;margin:0;padding:0;width:100%;height:100vh;overflow:hidden;display:block" :src="`${alunoAppUrl}curso/aula_atual?ls=${lStorage}`" frameborder=0></iframe>
        </v-dialog>
        <v-col class="pb-0" style="text-align: right;" cols="12" v-if="levels.length > 0">
            <v-btn small shaped v-for="(l, i) in levels" :key="i" exact @click="abrirApp(l.id_level)" text><v-icon left>mdi-cast-variant</v-icon> {{l.level}}</v-btn>
        </v-col>
        <v-col class="pb-0" style="text-align: right;" cols="12" v-else>
            <v-alert dense type="error" class="mb-0" v-html="'PROFESSOR COM NÍVEL INDEFINIDO'"/>
        </v-col>
        <v-col cols="12">
            <v-card>
                <v-toolbar flat dark color="primary" height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                    <v-spacer />
                    <v-btn exact :to="`/aula/alterar/${id_aula}`" icon><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn v-if="permissao('aula_excluir')" exact @click="excluir" icon><v-icon>mdi-delete</v-icon></v-btn>
                    <v-btn to="/aula" exact icon><v-icon>mdi-arrow-left</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" md="4" lg="3" class="py-0"><p class="text--primary mb-2"><strong>ID:</strong> {{aula.id_aula}}</p></v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" class="py-0"><p class="text--primary mb-2"><strong>Turma:</strong> {{aula.turma}}</p></v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" class="py-0"><p class="text--primary mb-2"><strong>Curso:</strong> {{aula.curso}}</p></v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" class="py-0"><p class="text--primary mb-2"><strong>Disciplina:</strong> {{aula.disciplina}}</p></v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" class="py-0"><p class="text--primary mb-2"><strong>Professor:</strong> {{aula.usuario_professor}}</p></v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" class="py-0"><p class="text--primary mb-2"><strong>Início:</strong> {{this.dataPt(aula.datahora_inicio)}}</p></v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" class="py-0"><p class="text--primary mb-2"><strong>Conclusão:</strong> {{this.dataPt(aula.datahora_fim)}}</p></v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" class="py-0"><p class="text--primary mb-2"><strong>Franquia:</strong> {{aula.franquia}}</p></v-col>
                        <v-col cols="12" v-if="aula.observacao"><p class="text--primary mb-2"><strong>Observações:</strong> {{aula.observacao}}</p></v-col>
                        <!--<v-col cols="12" sm="6"><p class="text--primary mb-2"><strong>Alunos presentes:</strong> - 45/47 - 90%</p></v-col>-->
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-toolbar flat dark color="primary" height="auto">
                            <v-toolbar-title class="py-0">Conteúdo</v-toolbar-title>
                            <v-spacer/>
                            <v-checkbox v-model="planejado_filtro" class="py-3 pr-4" hide-details label="Planejado"/>
                            <v-text-field v-model="conteudo_filtro" flat outlined dense hide-details label="Buscar:"/>
                        </v-toolbar>
                        <v-simple-table v-if="aula.curso_conteudo">
                            <template v-slot:default>
                                <tbody>
                                <tr style="cursor: pointer;" v-for="(cc, cci) in aula.curso_conteudo.filter((v) => {
                                    return removerAcentos(v.conteudo.toLowerCase()).search(removerAcentos(conteudo_filtro.toLowerCase())) >= 0 && (planejado_filtro ? v.conteudo_planejado == 't' : true)
                                })" :key="cci" :style="{backgroundColor: cc.conteudo_dado == 't' ? '#EBF3E1' : (cc.conteudo_planejado == 't' ? '#FFF7DE' : '#FFFFFF')}">
                                    <td style="width: 30px; border-left-width: 5px; border-left-style: solid;" :style="{borderLeftColor : cc.conteudo_dado == 't' ? '#3FA243' : (cc.conteudo_planejado == 't' ? '#FFC107' : '#CCCCCC')}">{{cc.quantidade_conteudo_lecionado}}x</td>
                                    <td style="width: 30px;"><v-checkbox @click="aula.aula_conteudo_plano.filter(v => v.id_conteudo == cc.id_conteudo).length ? conteudoRemover(cc.id_conteudo) : conteudoPlanejar(cc.id_conteudo)" v-model="aula.aula_conteudo_plano.filter(v => v.id_conteudo == cc.id_conteudo).length"/></td>
                                    <td @click="dialogConteudoAbrir(cc.id_conteudo)"><strong>{{cc.conteudo}}</strong></td>
                                    <td>{{cc.datahora_lecionado}}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
                <!--<v-col cols="12" sm="6" md="4" v-for="(cc, cci) in aula.curso_conteudo.filter((v) => {
                    return this.removerAcentos(v.conteudo.toLowerCase()).search(this.removerAcentos(conteudo_filtro.toLowerCase())) >= 0 && (planejado_filtro ? v.conteudo_planejado == 't' : true)
                })" :key="cci" class="pb-2">
                    <v-card style="cursor: pointer;" :style="{backgroundColor: cc.conteudo_dado == 't' ? '#EBF3E1' : (cc.conteudo_planejado == 't' ? '#FFF7DE' : '#FFFFFF')}">
                        <v-card-title style="font-size: 17px" class="py-0">
                            <v-checkbox @click="aula.aula_conteudo_plano.filter(v => v.id_conteudo == cc.id_conteudo).length ? conteudoRemover(cc.id_conteudo) : conteudoPlanejar(cc.id_conteudo)" v-model="aula.aula_conteudo_plano.filter(v => v.id_conteudo == cc.id_conteudo).length"/>
                            <strong @click="dialogConteudoAbrir(cc.id_conteudo)">{{cc.conteudo}}</strong>
                        </v-card-title>
                        <v-card-subtitle @click="dialogConteudoAbrir(cc.id_conteudo)">
                            Lecionado {{cc.quantidade_conteudo_lecionado}}x
                        </v-card-subtitle>
                    </v-card>
                </v-col>-->
            </v-row>
        </v-col>
        <v-col cols="12">
            <v-tabs v-model="tab" class="elevation-3" show-arrows>
                <v-tab>Alunos</v-tab>
                <v-tab>Traduções</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="elevation-3">
                <v-tab-item>
                    <v-divider/>
                    <v-simple-table v-if="true">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th>Aluno</th>
                                <th>Metas</th>
                                <!--<th>Aproveitamento</th>-->
                                <!--<th>Palavras buscadas</th>-->
                                <th>Presença</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr v-for="(a, i) in aula.alunos" :key="i">
                                <td>
                                    <router-link :to="`/aluno/gerenciar/${a.id_aluno}`"><!-- @click="dialogAluno = true" -->
                                        <strong>{{a.nome_completo}}</strong><br />
                                        <!--<small>Adv-Mid</small>-->
                                    </router-link>
                                </td>
                                <td><v-btn text @click="dialogMetaAbrir(a.id_aluno)" small>Incluir metas</v-btn></td>
                                <!--<td>70/100 conteúdos assistidos</td>-->
                                <!--<td>15 novas palavras / 30 palavras revisadas</td>-->
                                <td>
                                    <v-btn v-if="!a.presente_inicio" text color="green" small @click="registrarPresenca(a.id_aluno)">Registrar presença</v-btn>
                                    <v-chip small v-if="a.presente_inicio" color="green" dark>Presente - {{dataEnToHour(a.presente_inicio)}}</v-chip>
                                    <v-btn v-if="a.presente_inicio && !a.presente_fim" text small @click="registrarAusencia(a.id_aluno)">Registrar ausência</v-btn>
                                    <v-chip small v-else-if="a.presente_fim" color="red" dark class="ml-1">Ausente em {{dataEnToHour(a.presente_fim)}}</v-chip>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-tab-item>
                <v-tab-item>
                    <v-btn color="primary" dark class="float-right my-2 mr-5" @click="traducaoListar"><v-icon left>mdi-update</v-icon> Atualizar</v-btn>
                    <v-divider style="clear: both;"/>
                    <v-simple-table v-if="traducoes.total > 0">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th>Frase</th>
                                <th>Tradução</th>
                                <th class="hidden-sm-and-down">Idioma</th>
                                <th class="hidden-sm-and-down">Aluno(s)</th>
                                <th class="hidden-md-and-down">Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(t, i) in traducoes.lista" :key="i">
                                <td><a @click="dialogPalavra = true"><strong>{{t.texto_origem}}</strong></a></td>
                                <td>{{t.texto_destino}}</td>
                                <td class="hidden-sm-and-down">
                                    <strong>Origem:</strong> {{t.idioma_origem}}
                                    <br /><strong>Destino:</strong> {{t.idioma_destino}}
                                </td>
                                <td class="hidden-sm-and-down">{{t.alunos}}</td>
                                <td class="hidden-md-and-down">{{(parseInt(t.traducao_total) > 1) ? t.traducao_total + ' traduções' : 'Uma tradução'}}</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-tab-item>
            </v-tabs-items>
        </v-col>
        <v-dialog v-model="dialogAluno" max-width="550">
            <v-card>
                <v-card-title>Valmir J. R Junior</v-card-title>
                <v-card-text>
                    ** Gerar um relatório das palavras buscadas por Junior, horário de entrada na aula, conteúdos vistos, aproveitamento, e conteúdos não vistos por conta do atraso ou saída antecipada
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogPalavra" max-width="550">
            <v-card>
                <v-card-title>Palavra: Grape</v-card-title>
                <v-card-text>
                    ** Listar alunos que buscou a palavra "Grape" incluíndo o nível de cada um
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogMeta" max-width="550" scrollable>
            <v-card>
                <v-card-title>Metas</v-card-title>
                <v-divider/>
                <v-card-text class="pa-0">
                    <v-form>
                        <v-row>
                            <v-col cols="12" class="px-6"><v-textarea hide-details v-model="registroMeta.meta"/></v-col>
                            <v-col cols="12" class="px-3"><v-btn @click="metaCadastrar" text right style="float: right;">INCLUIR</v-btn></v-col>
                        </v-row>
                    </v-form>
                    <v-divider/>
                    <v-list>
                        <template v-for="(m, i) in dialogMetaConteudo.lista">
                            <v-list-item :key="i">
                                <v-list-item-content style="white-space: pre-line;" v-text="m.meta"></v-list-item-content>
                                <v-list-item-action><v-btn @click="metaConcluir(m.id_meta)" icon><v-icon>mdi-check</v-icon></v-btn></v-list-item-action>
                                <v-list-item-action><v-btn @click="metaExcluir(m.id_meta)" icon><v-icon>mdi-delete</v-icon></v-btn></v-list-item-action>
                            </v-list-item>
                            <v-divider :key="`d_${i}`"/>
                        </template>
                    </v-list>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="dialogMeta = false" text>Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogConteudoAluno" max-width="1600" scrollable>
            <v-card>
                <v-toolbar dark dense flat height="auto" class="py-1" color="#0E5381">
                    <v-toolbar-title>{{dialogConteudoAlunoEvolucaoListar.nome_completo}}</v-toolbar-title>
                    <v-spacer/>
                    <v-btn icon @click="dialogConteudoAluno = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
                <v-card-text style="border-top: 1px solid #CCCCCC; border-bottom: 1px solid #CCCCCC; background-color: #E4E4E4;" class="px-0 pb-0">
                    <v-row class="my-1 mx-2" v-for="(l, i) in dialogConteudoAlunoEvolucaoListar.level" :key="i">
                        <v-col cols="12"><h3 style="font-weight: normal;">{{l.level}}</h3></v-col>
                        <v-col v-for="(f, ind) in l.frase" :key="ind" cols="12" sm="6" md="4" lg="3" class="ma-0 pa-1">
                            <v-card :color="f.sei ? '#4CAF50' : undefined">
                                <v-card-text style="white-space: pre-line;" :style="{color : (f.sei ? '#FFFFFF' : '#333333')}">{{f.frase}}</v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogConteudo" max-width="1600" scrollable>
            <v-card>
                <v-toolbar dark dense flat height="auto" class="py-3" color="#0E5381">
                    <v-toolbar-title>{{dialogAulaConteudoEvolucaoListar.conteudo}}</v-toolbar-title>
                    <v-spacer/>
                    <v-progress-circular :rotate="360" size="30" :width="6" :value="timer" color="#FFFFFF"/>
                </v-toolbar>
                <v-tabs show-arrows v-model="tabAula">
                    <v-tab v-for="(l, li) in dialogAulaConteudoEvolucaoListar.level" :key="li">{{l.level}}</v-tab>
                </v-tabs>
                <v-card-text style="border-top: 1px solid #CCCCCC; border-bottom: 1px solid #CCCCCC; background-color: #E4E4E4;" class="px-0 pb-0">
                    <v-tabs-items v-model="tabAula" style="background-color: #E4E4E4;">
                        <v-tab-item v-for="(l, li) in dialogAulaConteudoEvolucaoListar.level" :key="li">
                            <v-row class="my-1 mx-2">
                                <v-col cols="12"><h2 style="font-weight: normal;">Revisar:</h2></v-col>
                                <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 pa-1" v-for="(c, i) in l.conteudo" :key="`p_${i}_${c.id_frase}`">
                                    <v-card>
                                        <v-card-text style="white-space: pre-line;">
                                            <span style="color: #333333">{{c.frase}}</span>
                                        </v-card-text>
                                        <v-divider/>
                                        <v-card-text>
                                            {{c.aluno_sei}}/{{c.aluno_total}} alunos sabem
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12"><h2 style="font-weight: normal;">Progresso individual:</h2></v-col>
                                <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 pa-1" v-for="(lf, li) in l.aluno" :key="`p_${li}_${lf.nome_completo}`">
                                    <AlunoCard @click="dialogConteudoAlunoAbrir(lf.id_aluno)" class="mb-1" :nome-completo="lf.nome_completo" :frase-total="lf.frase_total" :frase-sei-total="lf.frase_sei_total" />
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="dialogConteudo = false" text>Fechar</v-btn>
                    <v-btn @click="conteudoLecionar(dialogAulaConteudoEvolucaoListar.id_conteudo)" text>{{dialogConteudoIncluido ? 'Conteúdo incluído' : 'Incluir na aula'}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>

import axios from "axios"
import {mapState} from "vuex"
import AlunoCard from "@/Views/Aluno/AlunoCard";

export default {
    name: "AulaGerenciar",
    components: {AlunoCard},
    props : ['id_aula'],
    data() {
        return {
            conteudo_filtro : '',
            planejado_filtro : false,
            tab : null,
            dialogAluno : false,
            dialogPalavra : false,
            dialogConteudo : false,
            dialogMeta : false,
            dialogMetaConteudo : {},
            lStorage : null,
            registroMeta : {meta : ''},
            dialogAulaConteudoEvolucaoListar : {},
            dialogConteudoAlunoEvolucaoListar : {},
            dialogConteudoEvolucaoListar : {},
            dialogConteudoIncluido : false,
            dialogConteudoAluno : false,
            interval : null,
            timer : 0,
            tabAula: 0,
            aula : {
                curso_conteudo : []
            },
            traducoes : {total : 0, lista : []},
            dialogApp : false,
            levels : []
        }
    },
    computed: {
        ...mapState(['baseUrl', 'apiUrl', 'usuarioChave', 'alunoAppUrl', 'usuarioSessao'])
    },
    methods : {
        timerAtualizar() {
            if (this.timer >= 100) {
                if (this.dialogConteudo) {
                    this.dialogConteudoAbrir(this.dialogAulaConteudoEvolucaoListar.id_conteudo)
                }
                this.timer = 0
            } else {
                this.timer += 10
            }
        },
        abrirApp(id_level) {
            let st = JSON.parse(localStorage.getItem(this.usuarioChave))
            this.lStorage = btoa(unescape(encodeURIComponent(JSON.stringify({
                id_usuario   : st.id_usuario,
                id_grupo     : st.id_grupo,
                usuario      : st.usuario,
                email        : st.email,
                sessao_token : st.sessao_token,
                embed        : true,
                id_turma     : parseInt(this.aula.id_turma),
                id_curso     : parseInt(this.aula.id_curso),
                id_level     : id_level
            }))))
            this.dialogApp = true
        },
        levelListar() {
            return axios.post(`${this.apiUrl}aluno/level/listar`).then((res) => {
                this.levels = res.data.lista.filter(v => v.id_level >= parseInt(this.aula.id_level_professor)).reverse()
            })
        },
        registrarPresenca(id_aluno) {
            axios.post(`${this.baseUrl}aula/aluno_registrar_presenca`, {id_aula : this.id_aula, id_aluno : id_aluno}).then( (res) => {
                if(res.data) {
                    this.getAula()
                }
            })
        },
        registrarAusencia(id_aluno) {
            axios.post(`${this.baseUrl}aula/aluno_registrar_ausencia`, {id_aula : this.id_aula, id_aluno : id_aluno}).then( (res) => {
                if(res.data) {
                    this.getAula()
                }
            })
        },
        conteudoPlanejar(id_conteudo) {
            axios.post(`${this.apiUrl}aula/conteudo/planejar`, {id_conteudo : parseInt(id_conteudo), id_aula : parseInt(this.id_aula)}).then( (res) => {
                if(res.data) {
                    this.dialogConteudoIncluido = true
                    this.getAula()
                }
            })
        },
        conteudoLecionar(id_conteudo) {
            axios.post(`${this.apiUrl}aula/conteudo/lecionar`, {id_conteudo : parseInt(id_conteudo), id_aula : parseInt(this.id_aula)}).then( (res) => {
                if(res.data) {
                    this.dialogConteudoIncluido = true
                    this.getAula()
                }
            })
        },
        conteudoRemover(id_conteudo) {
            axios.post(`${this.baseUrl}aula/conteudo_remover`, {id_conteudo, id_aula : this.id_aula}).then( (res) => {
                if(res.data) {
                    this.dialogConteudoIncluido = false
                    this.getAula()
                }
            })
        },
        excluir() {
            let c = confirm("Essa ação não pode ser desfeita, tem certeza que deseja excluir?")
            if (c) {
                return axios.post(`${this.baseUrl}aula/excluir`, {id_aula: this.id_aula}).then((res) => {
                    if (res.data) {
                        this.$router.push({path: '/aula'})
                    } else {
                        alert("Erro ao excluir registro")
                    }
                })
            }
        },
        dialogConteudoAbrir(id_conteudo) {
            this.dialogConteudo = true
            this.dialogConteudoIncluido = false
            return axios.post(`${this.apiUrl}aula/conteudo/evolucao/listar`, {id_conteudo : parseInt(id_conteudo), id_aula : parseInt(this.id_aula)}).then( (res) => {
                this.dialogAulaConteudoEvolucaoListar = {
                    ...res.data,
                    id_conteudo : id_conteudo,
                    level : res.data.level.filter((v) => {
                        return v.aluno.length > 0
                    })
                }
            })
        },
        dialogConteudoAlunoAbrir(id_aluno) {
            return axios.post(`${this.apiUrl}aula/conteudo/aluno/evolucao/listar`, {id_aluno : id_aluno, id_conteudo : parseInt(this.dialogAulaConteudoEvolucaoListar.id_conteudo), id_aula : parseInt(this.id_aula)}).then( (res) => {
                this.dialogConteudoAlunoEvolucaoListar = res.data
                this.dialogConteudoAluno = true
            })
        },
        metaListar(id_aluno) {
            return axios.post(`${this.baseUrl}aluno/meta_listar`, {id_aluno, id_turma : this.aula.id_turma}).then( (res) => {
                this.dialogMetaConteudo = {
                    lista : res.data.lista
                }
                this.registroMeta = {
                    id_aluno,
                    meta : ''
                }
            })
        },
        traducaoListar() {
            return axios.post(`${this.apiUrl}aula/traducao/listar`, {id_aula : parseInt(this.id_aula)}).then( (res) => {
                this.traducoes = res.data
            })
        },
        async dialogMetaAbrir(id_aluno) {
            await this.metaListar(id_aluno)
            this.dialogMeta = true
        },
        metaCadastrar() {
            return axios.post(`${this.baseUrl}aluno/meta_cadastrar`, {...this.registroMeta, id_turma : this.aula.id_turma}).then((res) => {
                if (res.data) {
                    this.metaListar(this.registroMeta.id_aluno)
                }
            })
        },
        metaConcluir(id_meta) {
            return axios.post(`${this.baseUrl}aluno/meta_concluir`, {id_meta}).then( () => {
                this.metaListar(this.registroMeta.id_aluno)
            })
        },
        metaExcluir(id_meta) {
            return axios.post(`${this.baseUrl}aluno/meta_excluir`, {id_meta}).then( () => {
                this.metaListar(this.registroMeta.id_aluno)
            })
        },
        getAula() {
            return axios.post(`${this.baseUrl}aula/get`, {id_aula : this.id_aula}).then( (res) => {
                this.aula = res.data
            })
        },
        async init() {
            // console.log(this.lStorage, window.atob(this.lStorage))
            await this.getAula()
            await this.levelListar()
        }
    },
    activated() {
        this.init()
        this.traducaoListar()
        this.interval = setInterval(this.timerAtualizar, 1000)
    },
    deactivated() {
        clearInterval(this.interval)
    }
}
</script>

<style scoped>

</style>
