<template>
    <v-card @click="$emit('click')">
        <v-list>
            <v-list-item>
                <v-progress-circular :rotate="360" size="50" :width="6" :value="numero((fraseSeiTotal/fraseTotal)*100, 0)" color="teal">{{ numero((fraseSeiTotal / fraseTotal) * 100, 0)}}%</v-progress-circular>
                <v-list-item-content class="ml-3">
                    <v-list-item-title>{{nomeCompleto}}</v-list-item-title>
                    <v-list-item-subtitle>{{fraseSeiTotal}}/{{fraseTotal}} concluído</v-list-item-subtitle>
                </v-list-item-content>
                <!--<v-list-item-action>
                    <v-icon>mdi-menu-down</v-icon>
                </v-list-item-action>-->
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
export default {
    name: "AlunoCard",
    props: ["nomeCompleto", "fraseTotal", "fraseSeiTotal"]
}
</script>

<style scoped>

</style>